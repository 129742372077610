import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/leave/with/consents', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/leave/with/consents/' + id);
}

const studentData = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/leave/with/consents/form');
}

const storeStudentData = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/leave/with/consents', formData);
}

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/approval/step/update/status/' + id, formData);
}

const studentRequests = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/leave/with/consents/student');
}

const downloadAsExcel = (config)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/leave/with/consents/excel-export',{...config,responseType:'arraybuffer'});
}

export default {
    getAll,
    get,
    studentData,
    storeStudentData,
    update,
    studentRequests,
    downloadAsExcel
}
